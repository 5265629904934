.man__header{
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    margin-top: 4rem;
    background-image: url(../../assets/IMG-20230305-WA0048.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.main__header-container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;
    text-align: center;
}

.card{
    background: black;
    padding: 1.5rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-1);
    transition: var(--transition);
    opacity: .7;
}

.card:hover{
    background: black;
    border-color: white;
    transform: translateY(-0.5rem);
}

.main__header-left{
    margin-top: 2rem;
    width: 100%;
}

.main__header-cta{
    display: grid;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin-top: -1rem;
}

.first__cta{
    display: flex;
    gap: .5rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #0194e2;
}

.first__cta h4{
    color: #0194e2;
    font-size: .9rem;
}

.second__cta{
    display: flex;
    gap: .5rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #0194e2;
    margin-top: -2rem;
}

.second__cta h4{
    color: #0194e2;
    font-size: .9rem;
}

.main__header-left p{
    margin: 1rem 0 2rem;
    font-size: 1.2rem;
    color: #ff5653;
}

.man__header-left h1{
    color: #0194e2;
    font-size: 3.rem;
    margin-bottom: .5rem;
}

.main__header-left h4{
    color: #0194e2;
    font-size: 4.rem;
    margin-bottom: 2rem;
}

.main__header-right {
    display: none;
    
}

.main__header-image{
    position: relative;
    width: 150%;
}

.btn{
    display: flex;
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px){
    .main__header{
        padding:1rem 0;
    }

    .main__header-container{
        gap: 0;
    }
    .main__header-left p{
        font-size: 1.0rem;
    }

    .main__header-left h4{
        font-size: 1.3rem;
    }
    
    .main__header-left h1{
        font-size: 3rem;
    }
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 600px){

    .main__header{
        margin-top: 2rem;
    }
    
    .main__header-right{
        display: none;
    }
    .main__header-left{
        justify-content: center;
        align-items: center;
        width: 100%;
     
    }
    .main__header-left p{
        font-size: 0.8rem;
        color: #ff5653;
    }
    
    .main__header-left h1{
        color: #0194e2;
        font-size: 2rem;
    }

    .main__header-left h4{
        color: #0194e2;
        font-size: 0.8rem;
    }


    .main__header-container{
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: center;
    }
    .main__header{
        margin-bottom: -5rem;
    }
    .btn {
        font-size: 0.6rem;
    }

    .first__cta h4{
        color: #0194e2;
        font-size: .6rem;
    }

    .second__cta h4{
        color: #0194e2;
        font-size: .6rem;
    }
}
