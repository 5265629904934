*, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    /* GENERAL CONATAINER VARIABLES */
    --container-width-lg: 80%;
    --container-width-md: 90%;

    /* BORDER RADIUS VARIABLES */
    --border-radius-1: 1.2rem;
    --border-radius-2: 0.8rem;
    --border-radius-3: 0.5rem;

    /* GENERAL TRANSITION VARIABLES */
    --transition: all 500ms ease;
}


html {
    scroll-behavior: smooth;
}

body{
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
    overflow-x: hidden;
    font-size: 0.9rem;
    color: #0194e2;
    background: #2c3544;
}

/* GENERAL CONTAINER CLASS */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
    max-width: 1920px;
}

/* GENERAL SECTION STYLES */
section {
    padding: 10rem 0;
    transition: var(--transition);
}

section > h2 {
    text-align: center;
}

section > p {
    text-align: center;
    margin: 0.5rem auto 4rem;
    width: 40%;
}

/* GENERAL SECTION STYLES */
a {
    color: #eab676;
    transition: var(--transition);
}

/* GENERAL HEADING STYLES */
h1, h2, h2, h4, h5, h6{
    line-height: 1.1;
    color: #3b99c0;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.1rem;
}

h5 {
    font-size: 0.95rem;
}

h6 {
    font-size: 0.8rem;
}

/* GENERAL IMAGES STYLES */
.img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* GENERAL BUTTON STYLES */
.btn {
    display: inline-block;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 1rem;
    background: #ff5653;
    color: #ff5653;
    padding: 0.3rem 1rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-2);
    cursor: pointer;
}

.btn:hover{
    background: #ff5653;
    border-color: white;
    color: #ff5653;
    transform: translateY(-0.5rem);
}

.btn.white {
    background: white;
}

.btn.white:hover {
    background: transparent;
    border-color: white;
}

.btn.primary {
    background: #ff5653;
    color: black;
}

.btn.primary:hover {
    background: #ff5653;
    border-color: black;
    color: black;
}

.btn.sm{
    padding: 0.5rem 1.2rem;
}

.section__head{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.section__head span{
    background: black;
    padding: 0.5rem;
    border-radius: 2.5rem;
    color: #ebd36e;
    font-size: 1.9rem;
    align-items: center;
   
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px){
    body {
        font-size: 1rem;
    }
    
    .container{
        width: var(--container-width-md);
    }
    
    section{
        padding: 8rem 0;
    }
    
    section > p {
        width: 72%;
        margin-bottom: 3rem;
    }
    
    h1 {
        font-size: 2rem;
    }

    h2{
        font-size: 1.6rem;
    }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px){
    h1{
        font-size: 2.2rem;
        line-height: 1.2;
    }

    h2{
        font-size: 1.8rem;
    }

    h3{
        font-size: 1.4rem;
    }

    h4{
        font-size: 1rem;
    }

    .btn{
        padding: 0.6rem 1.5rem;
    }

    section > p{
        width: var(--container-width-md);
    }
}