.about__container{
    display: grid;
    grid-template-columns: 1fr;
}

.about__card{
  background: url(../../assets/IMG-20230305-WA0039.jpg);
}

.about__cards{
    margin: 3rem 0 1.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.about__card-icon {
    font-size: 1.3rem;
    color: white;
    border-radius: var(--border-radius-3);
    width: fit-content;
    margin-inline: auto;
    padding: 0.6rem;
    display: flex;
    align-items: center;
    transition: var(--transition);
}

.about__cards p{
    margin-top: 1.5rem;
    color: #ff5653;
}

.about__left{
    margin-top: 4rem;
}

.about__right p{
    margin-bottom: 0.8rem;
    color: #ff5653;
}

.about__right .btn{
    margin-top: 2.5rem;
}

.about__right h3{
    color: #0194e2;
}

.about__right h2{
    margin-bottom: 1.5rem;
    color: #0194e2;
}

.about__right h1{
    color: #eab676;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}
/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px){
    .about__cards{
        margin: 3rem 0 1.5rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1.5rem;
        text-align: center;
    }
}