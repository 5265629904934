.card{
    background: black;
    padding: 1.5rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-1);
    transition: var(--transition);
    opacity: .7;
}

.card:hover{
    background: black;
    border-color: white;
    transform: translateY(-0.5rem);
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px){
    padding: 1.2rem;
}